<template>
  <div
    v-if="applicationDetail.seller_company !== undefined"
    class="container"
    style="background:rgb(228, 230, 241);"
  >
    <!--begin::Page Title-->
    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
      Application for
      {{ businessProfile.company_name }}
    </h5>

    <div class="row">
      <div class="col-lg-4">
        <v-card>
          <v-toolbar color="indigo" dark>
            <v-card-title class="white--text ">
              <!-- <v-avatar size="75">
                <img
                  alt="user"
                  :src="
                    applicationDetail.businessProfile.logo !=
                    null
                      ? api_url +
                        applicationDetail.businessProfile.logo
                          .url
                      : '/media/product-placeholder.jpg'
                  "
                />
              </v-avatar> -->
              <h4 class="ml-2">
                {{ businessProfile.company_name }}
              </h4>
            </v-card-title>
          </v-toolbar>

          <v-card-text>
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ businessProfile.first_name }}
                    {{ businessProfile.last_name }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    {{ businessProfile.title }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-phone
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ businessProfile.phone }}</v-list-item-title
                  >
                  <v-list-item-subtitle>Phone</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-email
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ businessProfile.email }}</v-list-item-title
                  >
                  <v-list-item-subtitle>Business</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-home
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ businessProfile.website }}</v-list-item-title
                  >
                  <v-list-item-subtitle>Website</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <div class="card card-custom mt-7">
          <div class="card-header">
            <h5 class="card-title text-dark ">Trade References</h5>
          </div>

          <div class="card-body">
            <div class="col-lg-12">
              Trade Reference 1: <br />
              <span v-if="businessProfile.trade_ref1 != null">
                {{ businessProfile.trade_ref1 }}
              </span>
              <span v-else>
                <br />
                <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED</span
              >
            </div>
            <div class="col-lg-12">
              Trade Reference 2: <br />
              <span v-if="businessProfile.trade_ref2 != null">
                {{ businessProfile.trade_ref2 }}</span
              >
              <span v-else>
                <br />
                <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED</span
              >
            </div>
            <div class="col-lg-12">
              Trade Reference 3: <br />
              <span v-if="businessProfile.trade_ref3 != null">{{
                businessProfile.trade_ref3
              }}</span>
              <span v-else>
                <br />
                <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED</span
              >
            </div>
          </div>
        </div>
        <v-card class="bg-success ">
          <md-card-content>
            <h5 class="card-category card-category-social text-white">
              <i class="far fa-newspaper" /> New Partner
            </h5>
            <h4 class="card-title text-white">
              Applications Made Easy
            </h4>
            <p class="card-description text-white">
              Approve partners who you want to sell your products. This will
              give access to PIM, Messaging and Marketplace purchasing
            </p>

            <div class="card-stats text-center"></div>
          </md-card-content>
        </v-card>
      </div>

      <div class="col-lg-8">
        <div class="row">
          <div class=" col-lg-6">
            <v-card class="mx-auto h-100" max-width="400">
              <v-img
                class="white--text align-end"
                height="200px"
                :src="
                  businessProfile.storefront != null
                    ? api_url + businessProfile.storefront.url
                    : '/media/product-placeholder.jpg'
                "
              >
                <v-card-title>Submitted Storefront</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0"> </v-card-subtitle>

              <v-card-text class="text--primary">
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo">
                        mdi-map-marker
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ businessProfile.addressline1 }}
                        <br />
                        {{ businessProfile.addressline2 }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        {{ businessProfile.city }},
                        {{ businessProfile.state }}
                        {{ businessProfile.zip }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
          <div class=" col-lg-6">
            <v-card tile class="bg-white h-100">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">
                    <i class="material-icons text-primary">people </i>
                    Update Status
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <b-form-group>
                  <div class="row">
                    <div class="col-lg-6">
                      <b-form-select
                        id="input-horizontal"
                        v-model="selected"
                        :options="options"
                      ></b-form-select>
                    </div>
                    <div class="col-lg-6">
                      <b-button class="ml-3" @click="updateStatus"
                        >Update Status</b-button
                      >
                    </div>
                  </div>
                </b-form-group>

                <v-btn outlined @click="chatWithSeller">
                  Chat with this company
                </v-btn>
                <br />
                <br />
                <h5 class="text-dark">
                  If you need additional information, use the messaging function
                  below to ask questions or for more documents.
                </h5>
              </div>
            </v-card>
          </div>
        </div>

        <v-card tile class="bg-white mt-10">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <i class="material-icons text-primary">people </i>
                Application Info
              </h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <strong>Business Model:</strong>
                  </div>
                  <div class="col-lg-6">
                    {{ businessProfile.type | filter_business_profile }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <strong>Rating:</strong>
                  </div>
                  <div class="col-lg-6">
                    {{ businessProfile.rating | filter_business_rating }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <strong> Store Name:</strong>
                  </div>
                  <div class="col-lg-6">
                    {{ businessProfile.store_name }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <strong> Business Type:</strong>
                  </div>
                  <div class="col-lg-6">
                    {{ businessProfile.company_type | filter_business_type }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <strong> State of Incorporation:</strong>
                  </div>
                  <div class="col-lg-6">
                    {{ businessProfile.state_of_incorporation }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <strong> Number of Locations:</strong>
                  </div>
                  <div class="col-lg-6">
                    {{ businessProfile.number_of_locations }}
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <strong> Tax ID:</strong>
                  </div>
                  <div v-if="businessProfile.tax_id != null" class="col-lg-6">
                    {{ businessProfile.tax_id }}
                  </div>

                  <div v-else class="col-lg-6">
                    <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <strong> DUNS #:</strong>
                  </div>
                  <div v-if="businessProfile.duns != null" class="col-lg-6">
                    {{ businessProfile.duns }}
                  </div>

                  <div v-else class="col-lg-6">
                    <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-3">
                    <strong> W9:</strong>
                  </div>
                  <div class="col-lg-9">
                    <a
                      v-if="businessProfile.w9 != null"
                      :href="api_url + businessProfile.w9.url"
                      target="_blank"
                    >
                      <i class="material-icons text-primary"> book</i>
                    </a>
                    <a v-else height="120px">
                      <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED
                    </a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-3">
                    <strong> Resale Form:</strong>
                  </div>
                  <div class="col-lg-9">
                    <a
                      v-if="businessProfile.reseller_tax_form != null"
                      :href="api_url + businessProfile.reseller_tax_form.url"
                      target="_blank"
                    >
                      <i class="material-icons text-primary"> book</i></a
                    >
                    <a v-else height="120px">
                      <i class="flaticon2-cross icon-1x"></i> NOT PROVIDED
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <div class="row">
          <div class="col-lg-12">
            <v-card elevation="11" tile class="mt-4">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">
                    <i class="material-icons text-primary">people </i>
                    What makes
                    {{ businessProfile.company_name }}
                    special? <br />
                    <small>What the applicant says</small>
                  </h3>
                </div>
              </div>
              <v-card-text class="card-body pt-2">
                <v-container>
                  <span v-html="businessProfile.company_desc"></span>
                </v-container>
              </v-card-text>
            </v-card>

            <div class="card card-custom mt-5 card-fit card-border">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">
                    <i class="material-icons text-primary">people </i>
                    Who are
                    {{ businessProfile.company_name }}'s customers? <br />
                    <small>What the applicant says</small>
                  </h3>
                </div>
              </div>
              <div class="card-body pt-2">
                <span v-html="businessProfile.customer_desc"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div
              v-if="applicationDetail.supplier_company.has_question"
              class="card card-custom mt-7"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">
                    <i class="material-icons text-primary">people </i>
                    Your Custom App Questions
                  </h3>
                </div>
              </div>

              <div class="card-body">
                <div>
                  <b-card
                    v-for="answer in applicationDetail.answers"
                    :key="answer.id"
                    bg-variant="light"
                  >
                    <b-form-group label="Question:">
                      {{ answer.question.text }}
                    </b-form-group>

                    <b-form-group label="Answer:">
                      {{ answer.text }}
                    </b-form-group>
                  </b-card>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12"></div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center ">
      <b-button
        v-if="applicationDetail.status != 'approved'"
        class="ml-3"
        variant="outline-primary"
        @click="approve"
      >
        Approve
      </b-button>
      <b-button
        v-if="applicationDetail.status != 'denied'"
        class="ml-3"
        variant="outline-primary"
        @click="deny"
      >
        Deny
      </b-button>
    </div>
    <b-modal
      v-model="modalShowGreeting"
      centered
      title="Approve application?"
      @ok="handleOkGreeting"
    >
      <div class="d-block">
        <p>Introduce a Note:</p>
        <b-form-input
          v-model="greeting"
          placeholder="Add a comment"
        ></b-form-input>
      </div>
    </b-modal>
    <b-modal
      v-model="modalShowDeny"
      centered
      title="Deny application?"
      @ok="handleOkDeny"
    >
      <div class="d-block">
        <p>Introduce a Note:</p>
        <b-form-input
          v-model="reason"
          placeholder="Add a comment"
        ></b-form-input>
      </div>
    </b-modal>
    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title text-dark ">Message Center</h5>
          </div>

          <div class="container">
            <div
              class="d-flex align-items-start list-item card-spacer-x py-3"
              data-inbox="message"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div
                    class="mt-2 mr-3 font-weight-bolder  text-left"
                    data-toggle="view"
                  >
                    1/20/2020
                  </div>
                  <div class="flex-grow-1 mt-2 mr-2" data-toggle="view">
                    <div>
                      <span class="font-weight-bolder font-size-lg mr-2"
                        >Customer User Name -</span
                      >
                      <span class="text-muted"
                        >Thank you for ordering UFC 240 Holloway vs Edgar
                        Alternate camera angles...</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div
                    class="mt-2 mr-3 font-weight-bolder  text-left"
                    data-toggle="view"
                  >
                    1/21/2020
                  </div>
                  <div class="flex-grow-1 mt-2 mr-2" data-toggle="view">
                    <div>
                      <span class="font-weight-bolder font-size-lg mr-2"
                        >Supplier User Name -</span
                      >
                      <span class="text-muted"
                        >Thank you for ordering UFC 240 Holloway vs Edgar
                        Alternate camera angles...</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-form-textarea
              id="textarea"
              placeholder="Send Applicant a message..."
              rows="5"
              max-rows="18"
            ></b-form-textarea>
            <div class="row">
              <div class="col-lg-6">
                <b-form-file
                  :state="Boolean(file)"
                  placeholder="Send Attachment"
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </div>
              <div class="col-lg-4">
                <b-button class="ml-3" variant="outline-primary">Send</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import GetApplicationDetail from '@/graphql/queries/GetApplicationDetail.gql'
import ApprovedPartner from '@/graphql/mutations/ApprovedPartner.gql'
import DeniedPartner from '@/graphql/mutations/DeniedPartner.gql'

export default {
  filters: {
    filter_business_type: function(value) {
      switch (value) {
        case 'soleproprietor':
          return 'Sole Proprieter'
        case 'llc':
          return 'LLC'
        case 'corporation':
          return 'Corporation'
        default:
          return 'Sole Proprieter'
      }
    },

    filter_business_profile: function(value) {
      switch (value) {
        case 'localonline':
          return 'Local & Online'
        case 'brick':
          return 'Brick & Mortar Online'
        case 'onlineonly':
          return 'Online Only'
        default:
          return ''
      }
    },

    filter_business_rating: function(value) {
      switch (value) {
        case '0':
          return 'This Company does not have a rating'
        case 1:
          return '1 Star Rating'
        case 2:
          return '2 Star Rating'
        default:
          return 'No Rating'
      }
    },
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      greeting: '',
      reason: '',
      modalShowGreeting: false,
      modalShowDeny: false,
      applicationDetail: {},
      company_name: [],
      selected: null,
      options: [
        { value: null, text: 'Please update status' },
        { value: 'pending_moreinfo', text: 'Pending - Need more Info' },
        { value: 'pending_processing', text: 'Pending - Processing ' },
        {
          value: 'denied_requirement',
          text: 'Denied - Does not Meet Requirements',
        },
        { value: 'denied', text: 'Denied' },
        { value: 'approved', text: 'Approved' },
      ],
    }
  },
  apollo: {
    applicationDetail: {
      query: GetApplicationDetail,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
    },
  },
  computed: {
    businessProfile() {
      if (!this.applicationDetail.businessProfile) {
        return this.applicationDetail.seller_company.business_profile
      } else {
        return this.applicationDetail.businessProfile
      }
    },
  },
  watch: {
    applicationDetail(newValue) {
      if (newValue.status == 'pending') {
        if (newValue.reason == 'Need more Info') {
          this.selected = 'pending_moreinfo'
        } else {
          this.selected = 'pending_processing'
        }
      } else if (newValue.status == 'denied') {
        if (newValue.reason == 'Does not Meet Requirements') {
          this.selected = 'denied_requirement'
        } else {
          this.selected = 'denied'
        }
      } else if (newValue.status == 'approved') {
        this.selected = 'approved'
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.getApplicationTotalCount({
        params:
          'id=' +
          this.$route.params.id +
          '&supplier_company=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        if (res.data == 0) {
          this.$router.push('/crm/applications')
        }
      })
    }, 1000)
  },
  methods: {
    ...mapActions([
      'getContactInfo',
      'getContactTotalCount',
      'submitContact',
      'updateContact',
      'updateApplication',
      'getApplicationTotalCount',
      'getApplicationById',
    ]),
    chatWithSeller() {
      this.getApplicationById({
        id: this.$route.params.id,
      }).then((res) => {
        this.getContactInfo({
          supplier_company: res.data.supplier_company.id,
          seller_company: res.data.seller_company.id,
        }).then((contact) => {
          if (contact.data.length == 0) {
            this.submitContact({
              supplier_company: res.data.supplier_company.id,
              seller_company: res.data.seller_company.id,
              status: 'public',
            }).then(() => {
              this.$router.push(
                '/supplier/messaging/thread/' + res.data.seller_company.id
              )
            })
          } else {
            this.$router.push(
              '/supplier/messaging/thread/' + res.data.seller_company.id
            )
          }
        })
      })
    },
    approve() {
      this.modalShowGreeting = true
    },
    async handleOkGreeting() {
      try {
        await this.$apollo.mutate({
          mutation: ApprovedPartner,
          variables: {
            applicationId: this.$route.params.id,
            greeting: this.greeting,
          },
        })
        this.updatedMsg()
      } catch (error) {
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
    deny() {
      this.modalShowDeny = true
    },
    async handleOkDeny() {
      try {
        await this.$apollo.mutate({
          mutation: DeniedPartner,
          variables: {
            applicationId: this.$route.params.id,
            reason: this.reason,
          },
        })
        this.updatedMsg()
      } catch (error) {
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
    updatedMsg() {
      Swal.fire({
        title: '',
        text: 'The application has been successfully updated!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })

      location.reload()
    },
    updateStatus() {
      if (this.selected != null) {
        let status = ''
        let reason = ''
        switch (this.selected) {
          case 'pending_moreinfo':
            status = 'pending'
            reason = 'Need more Info'
            break
          case 'pending_processing':
            status = 'pending'
            reason = 'Processing'
            break
          case 'denied_requirement':
            status = 'denied'
            reason = 'Does not Meet Requirements'
            break
          case 'denied':
            status = 'denied'
            reason = ''
            break
          case 'approved':
            status = 'approved'
            reason = ''
            break
          default:
            status = 'pending'
            reason = ''
        }

        this.updateApplication({
          id: this.$route.params.id,
          status: status,
          reason: reason,
        })
          .then(() => {
            Swal.fire({
              title: '',
              text: 'The application has been successfully updated!',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })

            this.$router.push('/crm/applications')
          })
          .catch(() => {
            this.$bvToast.toast('An Error Occured!', {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: true,
            })
          })
      }
    },
  },
}
</script>
